
<script setup>
    const props = defineProps({
        "to": {},
        "icon": { type: String, default: "fa-plus" },
    })
</script>

<template>
    <component :is="props.to ? 'router-link' : 'a'" :to="props.to" class="d-flex justify-content-center align-items-center text-white afm-floating-button">
        <i class="fas" :class="icon" style="font-size: 1.25em;" />
    </component>
</template>

<style scoped>
    .afm-floating-button {
        position: absolute;
        right: 1.5rem;
        bottom: 1.5rem;
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 999px;
        text-decoration: none;
        box-shadow: 4px 8px 16px rgb(0%, 0%, 0%, 10%);
        background-color: #3575B3;

        transition: all 200ms ease-in-out;

        &:active {
            transition: all 0ms;
            background-color: #5D91C2;
        }
    }
</style>
